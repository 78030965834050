import React from "react";
import rocketMan2 from "../../static/Images/rocketman2.png";
import { Link } from "react-router-dom";
import { useInView } from "react-intersection-observer";
import { Grow } from "@mui/material";

const Super30 = () => {
  const { ref, inView } = useInView({ triggerOnce: true, threshold: 0.3 });
  return (
    <div ref={ref}>
      <Grow in={inView} timeout={0}>
        <div
          id="super30"
          className=" border bg-[#f5f9fa] px-10 py-10 flex items-center justify-between w-[1000px] mx-auto mt-16 rounded-3xl xl:w-[900px] lg:w-[800px]  md:flex-col md:items-center md:space-y-6 md:w-auto md:mx-[3%] sm:px-0"
        >
          <div className="w-[60%] sm:w-[80%] mb:w-[92%]">
            <p className="text-4xl font-semibold md:text-3xl mb:text-2xl">
              Introducing <span className="text-primary">Super 30</span>
            </p>
            <p className="mt-2">Your Ultimate Career Launchpad</p>
            {/* <p className="mt-6 text-gray-500 mb:mt-3">
              We are selecting 30 students. We will train these students for 3
              <br className="block mb:hidden" />
              months on everything required to get a deep understanding to
              <br className="block mb:hidden" />
              crack top product-based companies like Google, Meta, Amazon,
              <br className="block mb:hidden" /> Apple, Microsoft, LinkedIn,
              Flipkart, and more.
            </p> */}
            <p className="mt-6 text-gray-500 mb:mt-3">
              Our 'Super 30' batch is ready to take you through a 3-month
              bootcamp that will solve all of your career obstacles. We're
              engineering success, serving you all the building blocks needed to
              crack interviews at tech titans like Google, Meta, Amazon, Apple,
              Microsoft, LinkedIn, Netflix, and More
            </p>

            <Link to={"/about-us"}>
              <button className="mt-10 mb:mt-6 mb:text-sm mb:px-8 border-2 border-primary text-primary px-12 py-2 rounded-3xl  transition ease-in-out duration-500 hover:scale-105 hover:bg-primary hover:text-white">
                I Want To Be A Part Of Super 30
              </button>
            </Link>
          </div>

          <div>
            <img src={rocketMan2} alt="" className="w-[280px] mb:w-[180px]" />
          </div>
        </div>
      </Grow>
    </div>
  );
};

export default Super30;
