import React from 'react'

import Forbes from '../../static/Icons/Forbes-India-B.png';
import Startup from '../../static/Icons/startupbg (1).png';
import Iso from '../../static/Icons/ISObg.png';
import Cosmos from '../../static/Icons/cosmo.png';
import ResponsiveWrapper from "../../utils/ResponsiveWrapper";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import { Grow, Zoom } from "@mui/material";
import { useInView } from "react-intersection-observer";
import { AutoScroll } from "@splidejs/splide-extension-auto-scroll";



const Recognised = ({ background = "#fcfcfc", stripBg = "#fff" }) => {
  const { ref, inView } = useInView({ triggerOnce: true, threshold: 0.3 });
  return (
    <ResponsiveWrapper>
      <div className="mt-16 flex flex-col items-center justify-center bg-[#fcfcfc] mb:px-2 ">
        <div className=" flex items-center mb:justify-center mb:flex-col mb:mb-4 mb-7" ref={ref} >
          <p className="text-4xl font-semibold md:text-3xl mb:text-2xl mb:text-center m-4">
            Featured & Recognized by
          </p>
        </div>

        <div className="mb:block bg-white py-9 px-10 border mx-10 w-full rounded-xl shadow-lg ">
          <Splide
            options={{
              perPage: 4,
              type: "loop",
              drag: "free",
              arrows: false,
              pagination: false,
              autoScroll: {
                speed: 2, // Adjust the speed for smooth scrolling
                pauseOnHover: false,
                pauseOnFocus: false,
              },
              breakpoints: {
                1400: {
                  perPage: 3,
                },
                1100: {
                  perPage: 2,
                },
                800: {
                  perPage: 1,
                  padding: "3rem",
                },
              },
            }}
            extensions={{ AutoScroll }}
          >
            {imgData.map((item, index) => (
              <SplideSlide key={index} >
                <div >
                  <Image key={index} src={item} />
                </div>
              </SplideSlide>
            ))}
          </Splide>
        </div>

      </div>
    </ResponsiveWrapper>
  );
};

const Image = ({ src }) => (
  <img
    className="w-auto h-[35px] transition ease-in-out duration-500 hover:scale-110 cursor-pointer mb:h-[25px]"
    src={src}
    alt=""
    style={{ margin: "auto" }}
  />
);

const imgData = [Startup, Iso, Cosmos, Forbes];

export default Recognised;