import React from "react";

import Jainil from "../../static/Images/Jainil.jpg";
import Gaurav from "../../static/Images/Gaurav.jpg";
import Swaraj from "../../static/Images/Swaraj.jpg";
import Muskan from "../../static/Images/Muskan.jpg";
import Kunika from "../../static/Images/kunika.jpeg";
import Loganpriya from "../../static/Images/Logapriya.jpg";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import "@splidejs/react-splide/css/skyblue";
import { AutoScroll } from "@splidejs/splide-extension-auto-scroll";
import { useInView } from "react-intersection-observer";

import amazon from "../../static/Icons/amazon.png";
import google from "../../static/Icons/google.png";
import msft from "../../static/Icons/microsoft.png";

const MentorsSections = () => {
  const { ref, inView } = useInView({ triggerOnce: true, threshold: true });

  return (
    <div id="mentors" className="overflow-hidden relative">
      <p
        className="text-center text-4xl lg:text-3xl sm:text-2xl font-semibold mt-36 font-sans sm:mt-28"
        ref={ref}
      >
        Meet our Mentors
      </p>
      <div className="mt-10 mb:mt-0">
        <Splide
          options={{
            perPage: 4,
            type: "loop",
            drag: "free",
            arrows: false,
            pagination: false,
            autoScroll: {
              speed: 2, // Adjust the speed for smooth scrolling
              pauseOnHover: false,
              pauseOnFocus: false,
            },
            breakpoints: {
              1400: {
                perPage: 3,
              },
              1100: {
                perPage: 2,
              },
              800: {
                perPage: 1,
                padding: "3rem",
              },
            },
          }}
          extensions={{ AutoScroll }}
        >
          {Data.map((item, index) => (
            <SplideSlide key={index}>
              <Box
                image={item.image}
                name={item.name}
                position={item.position}
                company={item.company}
                link={item.link}
                inView={inView}
              />
            </SplideSlide>
          ))}
        </Splide>
      </div>
    </div>
  );
};

const Box = ({ image, position, name, company, link }) => (
  <div className="relative w-[270px] lg:justify-self-center lg:self-center sm:mx-auto sm:mt-8 mb:w-[220px] overflow-hidden">
    <img
      alt=""
      src={image}
      className="w-[270px] rounded-3xl filter bg-gradient-to-r from-indigo-500 to-indigo-500 drop-shadow-[5px_5px_5px_rgba(0,0,0,0.5)] opacity-900"
    />
    <div className="absolute inset-0 rounded-3xl w-[270px] mb:w-[220px]"></div>
    <a href={link} target="_blank" rel="noreferrer">
      <div className="absolute bottom-7 px-2 py-2 w-[250px] left-3 rounded-xl items-center mb:w-[200px] mb-2">
        <div className="text-center">
          <p className="font-semibold text-white font-sans">{name}</p>
          <p className="text-xs text-white mt-1 font-sans">{position}</p>
        </div>
      </div>
      <div className="container border-solid">
        <div className="flex justify-center relative border-solid">
          <div className="">
            <img
              alt=""
              src={company}
              className="h-[40px] -mt-3 bg-white pl-3 pr-3 pt-2 rounded-xl shadow-2xl pb-2 w-[110px] border-solid"
            />
          </div>
        </div>
      </div>
    </a>
  </div>
);

const Data = [
  {
    name: "Gaurav",
    position: "SWE - III ",
    company: google,
    image: Gaurav,
    link: "https://www.linkedin.com/in/gaurav-jha-776039119/",
  },
  {
    name: "Jainil",
    position: "SDE II ",
    company: msft,
    image: Jainil,
    link: "https://www.linkedin.com/in/jainilvachhani/",
  },
  {
    name: "Logapriya",
    position: "Sr. DS ",
    company: amazon,
    image: Loganpriya,
    link: "https://www.linkedin.com/in/logapriya-viswanathan-546626125/",
  },
  {
    name: "Swaraj",
    position: "SDE II ",
    company: msft,
    image: Swaraj,
    link: "https://www.linkedin.com/in/swaraj-software-engineer/",
  },
  {
    name: "Muskan",
    position: "SDE ",
    company: msft,
    image: Muskan,
    link: "https://www.linkedin.com/in/muskan-handa-8b087217a/",
  },
  {
    name: "Kunika",
    position: "SDE ",
    company: msft,
    image: Kunika,
    link: "https://www.linkedin.com/in/kunikamalhotra/",
  },
];

export default MentorsSections;