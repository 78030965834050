import React from "react";
import HeroAbout from "../static/Images/AboutUs/HeroAbout.png";
import verticalArrows from "../static/Icons/verticalArrows.png";
import WhatYouCanExpect from "../components/AboutUs/WhatYouCanExpect";
import BookClassesSection from "../components/AboutUs/BookClassesSection";
import MentorsSections from "../components/HomePage/MentorsSections";
import heroBg from "../static/Images/heroBg.png";
import { Fade } from "@mui/material";

const AboutUs = () => {
  return (
    <div className="space-y-14 flex flex-col">
      <Fade in={true} mountOnEnter unmountOnExit timeout={1200}>
        <div className="flex flex-col space-y-16 relative">
          <p className="text-center text-4xl font-semibold mt-20 lg:text-3xl mb:text-2xl">
            About Us
          </p>
          <img src={HeroAbout} alt="" className="mx-auto" />
          <div>
            <p className="px-[10%] font-semibold mb-4 text-2xl text-center">
              Unlock Your True Potential With Maangler
            </p>
            <p className="px-[10%] font-semibold text-center">
              Our proven industry-vetted approach helps young talent to upskill
              and pave the way to their aspiring careers. Empowering more than
              just mentorship and training, our exclusive partnerships with
              freelance platforms enable learners to secure paid internships of
              their dreams. With contributions from leading professionals at
              Microsoft, Amazon, Apple, Netflix, Google, and other tech giants,
              our learners are nurtured, guided, and mentored toward great
              achievements. The results are tangible — our students have
              witnessed an impressive 5x RoI from our program. If you're geared
              up to propel your career, take the leap with Maangler today and
              embark on the journey of unlocking your full potential.
            </p>
          </div>
          <div className="flex items-center mx-auto">
            <img alt="" src={verticalArrows} />
            <button className="font-semibold border-primary border-2 px-16 py-2 text-primary rounded-3xl hover:bg-primaryLight md:px-10 sm:px-6 ">
              Learn More
            </button>
          </div>
          <img src={heroBg} alt="" className="absolute left-[10%]" />
        </div>
      </Fade>
      <WhatYouCanExpect />
      {/* <BookClassesSection /> */}
      <div className="pb-20">
        <MentorsSections />
      </div>
    </div>
  );
};

export default AboutUs;
