import React, { useState, useEffect } from "react";
import clsx from "clsx";
import { useDispatch } from "react-redux";
import { isLoggedIn } from "../utils/helperFunctions";
import DesktopNavBar from "./DesktopNavBar";
import MobileSideBar from "./MobileSideBar";
import ProfileDrawer from "./ProfileDrawer/ProfileDrawer";
import Timer from "./Timer";
import logo from "../static/Icons/logo.png";
import { Link, useLocation } from "react-router-dom";
import ZohoSalesIQ from "../global/zoho";

const NavBar = () => {
  const [openDrawer, setOpenDrawer] = useState(false);
  const [openNavHead, setOpenNavHead] = useState(true);
  const [openProfileDrawer, setOpenProfileDrawer] = useState(false);
  const isAuthorized = isLoggedIn();
  const { pathname } = useLocation();

  // const useScript = (url, widgetCode) => {
  //   useEffect(() => {
  //     // Check if script is already added
  //     if (document.getElementById("zsiqscript")) return;

  //     // Create and configure the main script element
  //     const script = document.createElement("script");
  //     script.type = "text/javascript";
  //     script.defer = true;
  //     script.id = "zsiqscript";
  //     script.src = url;

  //     // Inline script to set up Zoho SalesIQ widget
  //     const inlineScript = document.createElement("script");
  //     inlineScript.type = "text/javascript";

  //     const code = `
  //       var $zoho = $zoho || {};
  //       $zoho.salesiq = $zoho.salesiq || {
  //         widgetcode: "${widgetCode}",
  //         values: {},
  //         ready: function() {}
  //       };
  //       (function() {
  //         var d = document;
  //         var s = d.createElement("script");
  //         s.type = "text/javascript";
  //         s.defer = true;
  //         s.src = "${url}";
  //         var t = d.getElementsByTagName("script")[0];
  //         t.parentNode.insertBefore(s, t);
  //       })();
  //     `;

  //     inlineScript.appendChild(document.createTextNode(code));

  //     // Append the inline script first, then the main script
  //     document.body.appendChild(inlineScript);
  //     document.body.appendChild(script);

  //     // Cleanup function to remove the scripts on component unmount
  //     return () => {
  //       if (document.getElementById("zsiqscript")) {
  //         document.body.removeChild(inlineScript);
  //         document.body.removeChild(script);
  //       }
  //     };
  //   }, [url, widgetCode]); // Dependency array includes url and widgetCode
  // };

  // const scriptUrl = "https://salesiq.zohopublic.in/widget";
  // const widgetCode =
  //   "siq899df102a5c2b677ca473d3ea63c3045d1a46b352c1616b8c8c24229af605c35";

  // useScript(scriptUrl, widgetCode);

  return pathname === "/account/sign-in" || pathname === "/account/sign-up" ? (
    <div className="flex items-center font-semibold justify-center border-b shadow-lg py-2 cursor-pointer">
      <img alt="logo" src={logo} className="w-16" />
      <p className="text-2xl">Maangler</p>
    </div>
  ) : (
    <div className="z-[9999]">
      {openNavHead &&
        (window.location.pathname === "" ||
          window.location.pathname === "//") && ( // remove extra / to get the this banner back
          <div className="bg-primaryLight w-full py-4 px-2 text-center text-sm relative">
            <span className="font-semibold">New to Maangler?</span> Grab a
            Limited-Time Offer: Cohorts starting from just ₹999/-. Checkout our{" "}
            <Link to={"/super-30"}>
              <span className="underline font-semibold text-primary">
                {" "}
                Super 30
              </span>{" "}
            </Link>
            program
            <br />
            {/* <span className="font-semibold text-base">
          5 hours, 59 minutes, and 50 seconds
        </span> */}
            <Timer />
            <button
              className="text-2xl absolute top-0 right-3"
              onClick={() => setOpenNavHead(false)}
            >
              ⨯
            </button>
          </div>
        )}
      <div
        className={clsx(
          "px-[6%] xl:px-[3%] lg:px-[2%] md:px-[1%]  w-full   flex items-center justify-between text-sm font-semibold bg-white py-1 shadow-2xl "
        )}
      >
        <DesktopNavBar
          isAuthorized={isAuthorized}
          navLinks={navLinks}
          setOpenProfileDrawer={setOpenProfileDrawer}
        />

        <MobileSideBar
          navLinks={navLinks}
          openDrawer={openDrawer}
          setOpenDrawer={setOpenDrawer}
          setOpenProfileDrawer={setOpenProfileDrawer}
        />
        <ProfileDrawer
          openProfileDrawer={openProfileDrawer}
          setOpenProfileDrawer={setOpenProfileDrawer}
        />
      </div>
      {/* <ZohoSalesIQ /> */}

      {/* <div
        className="phone-call cbh-phone cbh-green cbh-show cbh-static"
        id="clbh_phone_div"
        style={{
          position: "fixed",
          bottom: "75px",
          right: "-55px",
          zIndex: 80,
        }}
      >
        <a
          id="WhatsApp-button"
          href="tel:916264670814"
          className="phoneJs"
          title="Hritik Gupta"
        >
          <div className="cbh-ph-circle"></div>
          <div className="cbh-ph-circle-fill"></div>
          <div className="cbh-ph-img-circle1"></div>
        </a>
      </div> */}
      <div>{/* Other component code */}</div>
    </div>
  );
};

const navLinks = [
  {
    id: 3,
    title: "Python Internship Program",
    link: "/python-internship-program",
  },
  { id: 4, title: "Java Internship Program", link: "/java-internship-program" },
  // { id: 1, title: "Super 30 Program", link: "/super-30" },
  { id: 7, title: "Hire From Us", link: "/hiring" },
  { id: 2, title: "About Us", link: "/about-us" },

  // { id: 5, title: "Campus Ambassador", link: "/campus-ambassador" },
  { id: 6, title: "Academic Training", link: "/college-reach-out" },

  { id: 8, title: "Become a mentor", link: "/become-mentor" },
  { id: 8, title: "AI tools", link: "/resume-builder" },
];

const showableNavIds = [1, 2, 5, 8];

export default NavBar;
