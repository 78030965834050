import React, { useState } from "react";
import * as Yup from "yup";
import { useFormik } from "formik";
import axios from "axios";
import { toast } from "react-toastify";
import { Cancel } from "@mui/icons-material";
import { Modal, FormControl, FormLabel, Radio, RadioGroup, FormControlLabel, TextField, CircularProgress } from "@mui/material";
import Thankyou from "../../static/Thankyou.jpg"; // Ensure the correct path

const ApplyNowModal = ({ show, setShow }) => {
  const [interest, setInterest] = useState("");
  const [designation, setDesignation] = useState("");
  const [formSubmitted, setFormSubmitted] = useState(false); // Track form submission
  const [loading, setLoading] = useState(false); // Track loading state

  const phoneRegex = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/;

  const { values, errors, touched, handleChange, handleSubmit, resetForm } = useFormik({
    initialValues: {
      name: "",
      email: "",
      phone: "",
      clgName: "",
      exp: "",
      companyName: "",
    },
    validationSchema: Yup.object().shape({
      name: Yup.string().required("Name is required"),
      phone: Yup.string()
        .matches(phoneRegex, "Invalid phone number")
        .required("Phone is required"),
      email: Yup.string()
        .email("Invalid email format")
        .required("Email is required"),
      clgName: Yup.string().required("College / Company Name is required"),
      exp: Yup.string().required("Year of graduation/Experience is required"),
    }),
    onSubmit: async (values) => {
      setLoading(true); // Show loader
      const payload = {
        ...values,
        interest: interest,
        designation: designation,
      };
      try {
        // Send data to your backend
        await axios.post("https://maangler-backend.vercel.app/applyNow", payload);

        // Send WhatsApp campaign
        const data = {
          apiKey: "ee8e38ea-83b8-4cce-9248-7e1a926d95ee",
          campaignName: "welcomemaangler",
          whatsappNumber: payload.phone,
          contactName: payload.name,
          attributes: {
            CollegeName: payload.clgName,
            Company: payload.companyName,
            email: payload.email,
            Experience: payload.exp,
            Interest: payload.interest,
            Course: "Full Stack Batch",
            Source: "Facebook Ads",
          },
          templateVariables: [payload.name],
        };
        await axios.post("https://api.sandeshai.com/whatsapp/campaign/api/", data);

        // Show success message and Thank You screen
        toast.success("Form submitted successfully");
        setFormSubmitted(true);
        resetForm();

        // Change URL after submission
        window.history.pushState({}, "", "/form-submitted-successfully");
      } catch (err) {
        toast.error(err.message);
      } finally {
        setLoading(false); // Hide loader
      }
    },
  });

  return (
    <Modal open={show} sx={{ zIndex: 9999999999 }} style={{ overflow: "auto" }}>
      <div className="p-10 mb:p-2 border-none outline-none overflow-y-auto">
        <div className="border p-6 shadow-2xl rounded w-full bg-white max-w-[1000px] mx-auto overflow-auto">
          <div onClick={() => setShow(false)}>
            <Cancel
              sx={{ marginLeft: "auto", display: "block", cursor: "pointer" }}
            />
          </div>
          {formSubmitted ? (
            <div
              className="text-center"
              style={{
                maxWidth: "400px", // Adjust the width of the div
                margin: "0 auto", // Center align the div
              }}
            >
              <img
                src={Thankyou}
                alt="Thank You"
                style={{
                  maxWidth: "100%", // Ensure the image fits the container
                  height: "auto",
                  objectFit: "contain",
                }}
              />
              <p
                className="text-lg mt-4" // Adjusted text size
                style={{
                  fontSize: "12px", // Reduce the text size
                  lineHeight: "1.5", // Improve readability with line height
                }}
              >
                Thanks for applying for <b>Super-30 Batch</b>. <br />
                Our Academic Counsellor will reach out to you within the next 24 hours.
              </p>
            </div>
          ) : (
            <div className="space-y-10">
              <div>
                <FormControl>
                  <FormLabel id="demo-radio-buttons-group-label">
                    Are you a College Student, Working Professional or Graduated
                    but not working? *
                  </FormLabel>
                  <RadioGroup
                    aria-labelledby="demo-radio-buttons-group-label"
                    defaultValue=""
                    name="radio-buttons-group"
                    onChange={(e) => setDesignation(e.target.value)}
                  >
                    <FormControlLabel value="College Student" control={<Radio />} label="College Student" />
                    <FormControlLabel value="Working Professional" control={<Radio />} label="Working Professional" />
                    <FormControlLabel value="Graduated but not working" control={<Radio />} label="Graduated but not working" />
                  </RadioGroup>
                </FormControl>
              </div>
              <div className="grid grid-cols-2 gap-6 mt-4 sm:grid-cols-1">
                {formData.map((item) => (
                  <TextField
                    key={item.name}
                    label={item.label}
                    variant="outlined"
                    required
                    error={Boolean(errors[item.name]) && touched[item.name]}
                    helperText={
                      Boolean(errors[item.name]) && touched[item.name]
                        ? errors[item.name]
                        : ""
                    }
                    onChange={handleChange}
                    name={item.name}
                    value={values[item.name]}
                  />
                ))}
              </div>
              <div className="mt-6 w-[80%] mx-auto flex justify-center items-center space-x-3">
                {loading ? (
                  <div className="flex items-center justify-center space-x-2">
                    <CircularProgress size={24} color="primary" />
                    <span className="text-gray-500 text-lg">Loading...</span>
                  </div>
                ) : (
                  <button
                    onClick={handleSubmit}
                    className="bg-primary text-white w-full py-2 rounded-lg shadow-lg transition duration-300 ease-in-out hover:bg-primary-dark"
                  >
                    Submit
                  </button>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    </Modal>
  );
};

const formData = [
  { id: 1, name: "name", label: "Candidate Full Name" },
  { id: 2, name: "email", label: "Email ID" },
  { id: 3, name: "phone", label: "Phone Number" },
  { id: 4, name: "clgName", label: "College / Company Name" },
  { id: 5, name: "exp", label: "Year of graduation/Experience" },
];

export default ApplyNowModal;
